.custom-checkbox {
    .form-check {
        input {
            width: 20px;
            height: 20px;
        }

        label {
            cursor: pointer;
            margin-left: 5px;
            vertical-align: middle;
        }
    }
}

.weekly-days-checkbox {
    label {
        width: 45px;
        height: 45px;
        cursor: pointer;
        border-radius: 50%;

        .checkbox-label {
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            cursor: pointer;
            align-items: center;
            border-radius: 3px;
            justify-content: center;
            border: 2.4px solid #9f9f9f;
            border-radius: 50%;
            background-color: #9f9f9f;
            &:hover {
                color: #ffffff;
                border-color: #506fd9;
                background-color: #506fd9;
            }
        }

        input {
            display: none;

            &:checked+ {
                .checkbox-label {
                    color: #ffffff;
                    border-color: #506fd9;
                    background-color: #506fd9;
                }
            }
        }


    }
}

.weekly-schedule-collapse {
    .accordion {
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    &:not(.collapsed) {
                        background-color: #f3f3f3;
                    }

                    &:focus,
                    &:active {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}