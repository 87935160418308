.common-loader{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 90;
    // background-color: rgb(70 82 95 / 49%);
    background-color: rgb(33 40 48 / 30%);
    transition: all 0.25s;
    align-items: center;
    .loader-content {
        position: relative;
        z-index: 2;
    }
   
}

.offcanvas.offcanvas-end {
    .common-loader {
        position: absolute;
    }
}