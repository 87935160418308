.default-branch {
    display: none;
    border-radius: 25px;
    padding: 2px 6px;
    border: 1px solid #424242;
    background-color: #f1f1f1;
}

.btn.setup-bttn {
    margin-left: 10px;
    display: none;
    border-radius: 6px;
    padding: 2px 8px;
    i {
        vertical-align: middle;
    }
}

.custom-dropdown-action.dropdown-toggle {
    &::after {
        display: none;
    }
}