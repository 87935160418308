.searchable-items-view {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    padding: 10px 0;
    overflow-y: auto;
    position: absolute;
    height: calc(100vh - 120px);
    background-color: #ffffff;
}