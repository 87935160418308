.my-card {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: 100vh;
    background-clip: padding-box;
    outline: 0;
    background-color: #ffffff;
    position: relative;
    border-left: 1px solid #EBEBEB;
    .my-card-header {
        .nav-tabs.card-tabs {
            padding: 0;
            border: 0;
            .nav-item {
                .nav-link {
                    border: 0;
                    color: #8A8A8A;
                    border-radius: 0;
                    background-color: transparent;
                    &:hover,
                    &.active {
                        color: #ffffff;
                        background-color: #dc3545;
                    }
                }
            }
            
        }
    }

    .my-card-body {
        padding: 0;
        flex-grow: 1;
        overflow-y: auto;
        .custom-tab-body {
            padding: 1rem;
            padding-bottom: 100px;
        }
    }

    .my-card-footer {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 70px;
        z-index: 5;
        padding: 1rem 1.5rem;
        background-color: #ffffff;
        border-top: 1px solid #e9e9e9;
        &.order-added-modal-footer {
            button {
                border-radius: 0;
                text-transform: uppercase;
                &.order-print-bttn {
                    flex: 50px 0;
                    height: auto;
                }
            }


          .btn-group {
            width: 100%;
            .custom-dropdown-action {
                padding-left: 12px;
                padding-right: 12px;
            }
          }
        }
    }
}

.category-menu {
    &.card {
        padding: 0;
        border: 0;
        margin-bottom: 20px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08);
        .card-body {
            padding: 0;
            button {
                width: 100%;
                padding: 1rem;
                text-align: left;
                border: 0;
                background-color: transparent;
                h2 {
                    font-size: 14px;
                    font-weight: 400;
                }
    
                i {
                    top: 50%;
                    right: 10px;
                    font-size: 20px;
                    position: absolute;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.added-item-title {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .my-card {
        .my-card-footer {
            .btn-group {
                .btn {
                    font-size: 12px;
                    text-transform: capitalize;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .my-card {
        margin-top: 2rem;
    }
}