
/** GENERAL APP STYLES **/

.sub-menu-page {
  min-height: 0;
  // height: calc(100vh - #{$height-header});
  margin-top: $height-header;
  position: relative;
  .contact-panel {
    height: calc(100vh - #{$height-header});
    &.contact-panel-custom {
      height: calc(100vh - 200px);
    }
  }

  &.show {
    @include media-breakpoint-down(lg) {
      .file-sidebar {
        left: 0;
        border-right: 1px solid rgba($border-color, .6);
      }

      .file-content { transform: translateX(230px); }
    }
  }
}
.page-app {
  .main-app {
    min-height: 0;
    height: calc(100vh - #{$height-header});
  }

  .main-footer {
    margin-top: 0;
    border-top-width: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    span + span {
      margin-top: 2px;
      @include media-breakpoint-up(sm) { margin-top: 0; }
    }
  }

  .header-link {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    font-size: 18px;
    @include transition(all 0.25s);

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  .dropdown-menu {
    border-color: rgba($border-color, .6);
    border-width: 0;
    border-radius: 6px;
    @include shadow-sharp($gray-400);
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 7px 10px;

    i {
      font-size: 18px;
      line-height: 1;
      margin-right: 10px;
    }
  }
}
