.existing-address {
  .selected-address {
    margin-bottom: 10px;

    label {
      display: flex;
      font-size: 14px;
      cursor: pointer;
      padding: 10px;
      padding-right: 8px;
      border-radius: 3px;
      border: 1px solid #d9d9d9;
      justify-content: space-between;
    }

    input {
      display: none;

      &:checked + label {
        color: #dc3545;
        border-color: #dc3545;
        background-color: #ffeaea;
      }
    }
  }
}

.msg-rating-bttn {
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  color: #030303;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  border: 1px solid #030303;

  &:hover,
  &.active {
    color: #ffffff;
    background-color: #030303;
  }
}

.share-via {
  a {
    width: 70px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed transparent;
    border-radius: 8px;
    &:hover,
    &.active {
      border-color: #2e7de3;
    }
  }
  .active {
    border: 1px dashed #2e7de3;
  }
}
