.sale-on-point-tabs {
    &.main-category-tabs {
        .nav-item {
            .nav-link {
                padding: 10px 20px;
                border-radius: 0;

                &.active {
                    color: #fff;
                    background-color: #dc3545;
                }
            }
        }
    }
}

.waiter-search-dropdown {
    z-index: 9;
    position: relative;

    .css-b62m3t-container {
        width: 130px;
    }

    .css-yk16xz-control {
        outline: none !important;
        box-shadow: none !important;
    }


    .css-yk16xz-control:focus {
        border-color: #000 !important;
        /* Change the border color on focus */
        box-shadow: none !important;
    }


    .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .css-13cymwt-control {
        border: 0;
        height: auto;
        padding: 0;
        min-height: 0;
    }

    .css-1fdsijx-ValueContainer {
        padding-right: 0;
    }
}

// .sub-category-tabs {
//     background-color: #ffffff;
//     height: calc(100vh - 118px);
// }

// NEW ASSIGNED TABLE STATE
.new-assigned-state {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    .empty-state-img {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        border-radius: 50%;
        background-color: #f7f7f7;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 50%;
        }
    }

    h3 {
        font-size: 18px;
        margin-top: 10px;
        text-transform: uppercase;
    }
}

// MEW GUEST FIELD
.new-guest-field {
    padding: 20px;
    background-color: #f7f7f7;
}

@media only screen and (max-width: 480px) {
    .table-state-my-card.offcanvas.offcanvas-end {
        overflow-y: auto;
        border-left: 0;

        .my-card {
            .my-card-body {
                .custom-tab-body {
                    padding: 1rem 1rem 50px 1rem;
                }
            }
        }
    }


}