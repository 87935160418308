[data-skin="dark"] {

    // BG GRAY 200
    .bg-gray-200 {
        background-color: #141926 !important;
    }

    .divider {
        span {
            color: #ffffff;
        }
    }

    // BUTTON OUTLINE DARK
    .btn-outline-dark {
        color: #ffffff;
        border-color: #222b41;
    }

    // CARD STYLE
    .card {
        background-color: $dark-highlight;
        border-color: $border-color-dark;

        &.card-one {
            background-color: #192030;
            border-color: #192030;

            .indicator-varient {
                img {
                    filter: drop-shadow(16px 16px 20px red) invert(75%);
                }
            }
        }
    }

    // MY CARD
    .my-card {
        background-color: #141926;
        border-color: #000000;

        .my-card-footer {
            background-color: #141926;
            border-color: #000000;
        }
    }

    // PAYMENT MODE STATUS BTTN
    .payment-mode-status-bttn {
        .form-check {
            label {
                color: rgba(255, 255, 255);
            }
        }
    }

    // FORM LABEL
    .form-label {
        color: #ffffff;
    }

    // CUSTOM LABEL
    .custom-label {
        color: #5e636f;
        background-color: #192030;
    }

    // DATATABLE CSS
    .cfvlby,
    .cviWVp,
    .cxFtRn {
        color: rgba(255, 255, 255);
        background-color: #192030;
    }

    // CVIWVP HOVER
    .cviWVp:hover {
        color: rgba(255, 255, 255);
        background-color: #141926;
        border-bottom-color: #141926;
        outline-color: #141926;
    }

    // OFFCANVAS MODAL
    .offcanvas.offcanvas-end {
        background-color: #141926;
    }

    // OFFCANVAS HEADER
    .offcanvas-header {
        border-bottom: 1px solid #222b41;
    }

    // OFFCANVAS FOOTER
    .offcanvas-footer {
        border-top: 1px solid #222b41;
    }

    // SUB CATEGORY TAB
    .sub-category-tabs {
        .nav-pills {
            .nav-item {
                background-color: #141926;

                .nav-link {
                    border-color: #192030;
                    background-color: #192030 !important;

                    &.active {
                        border-left: 5px solid #dc3545;
                    }
                }
            }
        }

        .sab-menu-tabs,
        .sub-menu-content {
            background-color: #141926;
        }

        .sub-menu-content {
            .card-one {
                background-color: #192030;
            }
        }
    }

    // QUANTITY
    .qty {
        button {
            color: rgba(255, 255, 255);
            border-color: rgba(255, 255, 255);
        }

        .count {
            color: rgba(255, 255, 255);
        }
    }

    // NAV TABS
    .nav-tabs {
        --bs-nav-tabs-border-color: #222b41;
    }

    // BG WHITE
    .bg-white {
        background-color: #192030 !important;
    }

    // MAIN CATEGORY TABS WRAPPER
    .main-category-tabs-wrapper {
        border-color: #222b41;

        &.sticky-main-category {
            background-color: #141926;
        }
    }

    // CSS 13CYMWT CONTROL
    .css-13cymwt-control {
        background-color: #192030;
        border-color: #2b3651;
    }

    // CHOOSE VARIANT BODY AND SUB HEADER
    .choose-variant-body {
        .variant-sub-header {
            color: #ffffff;
            background-color: #141926;
        }

        // CHOOSE VARIANT SELECT
        .choose-variant-select {
            .choose-varianr-radio {
                .radio-btn {
                    color: #ffffff;
                    border-color: #858585;

                    .variant-price {
                        color: #000000;
                    }
                }
            }
        }
    }

    // BILL PRINT WRAPPER
    .bill-print-wrapper {
        background-color: #141926;
        border: 1px solid #27314b;
    }

    // FONT STYLE BUTTON
    .font-style-bttns {
        span {
            color: #ffffff;
            background-color: #080a0c;
        }
    }

    // PERMISSION STATUS
    .permission-status {
        .react-checkbox-tree {
            .rct-node-parent {
                color: #ffffff;
                background-color: #141926;
            }
        }
    }

}