.custom-label {
    position: absolute;
    background-color: #f1f1f1;
    top: 1px;
    bottom: 1px;
    left: 1px;
    margin: 0;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem 0 0.25rem 0;
}

.tax-percentage-input {
    padding-left: 50px;
    overflow: hidden;
}