// PAGE IN TABS
.page-in-tabs {
    .nav-pills {
        display: block;

        .nav-item {
            width: 100%;
            margin-bottom: 5px;

            .nav-link {
                width: 100%;
                padding: 10px;
                background-color: transparent;

                &:hover,
                &.active {
                    color: #506fd9;
                    background-color: #f8f9fc;
                }
            }
        }
    }
}

// COMMON TABS
.common-tabs {
    .nav-pills {
        width: 100%;
        border-bottom: 1px solid;

        .nav-item {
            display: block;

            .nav-link {
                color: #6e7985;
                padding: 10px;
                background-color: transparent;

                &.active {
                    color: #506fd9;
                    border-color: #506fd9;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .sale-on-point-tabs {
        display: flex;
        padding: 1px 0;
        li {
            text-wrap: nowrap;
        }
    }
}