.badge {
  font-weight: 400;
  font-size: 11px;
  font-family: $font-family-sans-serif;
  border-radius: 2px;

  &:hover,
  &:focus { color: #fff; }

  &.bg-warning,
  &.bg-light { color: $dark; }

  &.badge-outline-dark {
    color: $dark;
    border: 1px solid $dark;
    text-align: center;
    font-size: 12px;
    border-radius: 3px;
    &.item-count {
      width: 30px;
      margin-right: 5px;
    }
}
}

.badge-pill { border-radius: 10px; }

.badge-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
