.price-variant-collapse {
    .card {
        .card-header {
            position: relative;

            .top-view {
                position: relative;
                z-index: 2;
                display: inline-block;

                input {
                    vertical-align: middle;
                }
            }

            .collapse-toggle-bttn {
                position: absolute;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                text-align: right;

                i {
                    font-size: 20px;
                }

                &[aria-expanded="true"] {
                    i {
                        font-size: 18px;
                        display: inline-block;
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                    }
                }
            }

            .unavailable-branch {
                display: none;
            }
        }

        &.disbaled-collapse {
            .card-header {
                .branch-name-toggle {
                    color: #b7b7b7;
                }

                .unavailable-branch {
                    margin-left: 10px;
                    display: inline-block;
                }
            }
        }
    }
}