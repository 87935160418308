body {
    color: #000 !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul {
        margin: 0;
        padding: 0;
    }

    a {
        cursor: pointer !important;
    }

    .z-index-2 {
        z-index: 2 !important;
    }

    .z-index-10 {
        z-index: 10 !important;
    }

    .z-index-99 {
        z-index: 99 !important;
    }

    .overflow-y {
        overflow-y: scroll;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .right-10 {
        right: 10px !important;
    }

    .top-10 {
        top: 10px !important;
    }

    .pl-0 {
        padding-left: 0 !important;
    }

    .pr-0 {
        padding-right: 0 !important;
    }

    .w-50 {
        width: 50%;
    }

    .bg-opacity-10 {
        --bs-bg-opacity: 0.10;
    }

    .text-left {
        text-align: left !important;
    }

    .text-right {
        text-align: right !important;
    }

    .flex-100 {
        flex: 0 100% !important;
    }

    img {
        object-fit: cover;

        &.object-fit-contain {
            object-fit: contain;
        }
    }

    .divider {
        span {
            color: #000000;
            font-weight: 600;
        }
    }

    .form-control,
    .form-select {
        &:focus {
            border-color: #424242;
        }
    }

    .form-check-input {
        cursor: pointer;
    }

    .btn-icon,
    .custom-btn-icon {
        width: 30px;
        height: 30px;

        &.dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }


    .btn-outline-success:hover,
    .btn-outline-secondary:hover {
        color: #ffffff;
    }

    .btn-outline-success {
        &:focus {
            background-color: #0cb785;
        }
    }

    .btn-outline-danger {
        &:focus {
            background-color: #dc3545;
        }
    }

    .btn-outline-dark {
        &:focus {
            background-color: #212830;
        }
    }

    .btn:first-child {

        &:focus,
        &:active {
            color: #ffffff;
        }
    }

    .btn-outline-secondary:focus {
        color: #6e7985;
    }

    .react-select-container {
        z-index: 99;
    }

    .offcanvas-backdrop {
        z-index: 1045;
    }

    .top-header {
        top: 72px;
        z-index: 9;
        padding: 10px 0;
        position: sticky;
    }

    table {
        color: #000 !important;
    }

    table thead tr th,
    table tbody tr td {
        vertical-align: middle;
        white-space: nowrap;
    }


    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        white-space: nowrap;
    }

    .main-app {
        &.manage-item-page {
            height: calc(100vh - 80px);
            overflow-y: auto;
        }
    }

    // TWO THREE LINE ELLIPSIS
    .two-line-ellipsis,
    .three-line-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .two-line-ellipsis {
        -webkit-line-clamp: 2;
    }

    .three-line-ellipsis {
        -webkit-line-clamp: 3;
    }

    // CARD ONE
    .card-one {
        .card-body {
            overflow-y: auto;
        }
    }

    .scrollbar-container {
        .card-one {
            .card-body {
                overflow-y: inherit;
            }
        }
    }

    // CARD > CARD DISH
    .card-one.dishes-card {
        cursor: pointer;

        .card-body {

            .top-badge {
                position: absolute;
                left: 15px;
                top: 10px;
            }

            .dish-img {
                width: 70px;
                height: 70px;
                overflow: hidden;
                border-radius: 50%;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .dish-title {
                color: #212830;
                font-size: 14px;
                font-weight: 600;
                margin: 5px 0;
                line-height: 20px;
                margin-top: 15px;
            }
        }

        &:hover {
            .card-body {
                .dish-title {
                    color: #506fd9;
                }
            }
        }
    }

    // OFF CANVAS CSS BEGIN
    .offcanvas-header {
        border-bottom: 0;
        box-shadow: 0px 1px 5px 1px rgba(64, 60, 67, 0.16);
    }

    .offcanvas.offcanvas-end {
        background-color: #ffffff;

        &.off-canvas-custom-width {
            width: 45%;

            .offcanvas-header {
                height: 72px;
                padding: 1rem 1.5rem;
            }

            .offcanvas-body {
                padding: 1rem 1.5rem;
            }
        }

        .custom-offcanvas-header {
            .btn-close {
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }

        .offcanvas-footer {
            display: flex;
            align-items: center;
            position: sticky;
            bottom: 0;
            left: 0;
            right: 0;
            height: 70px;
            padding: 1rem 1.5rem;
            justify-content: space-between;

            box-shadow: 0 1px 1px rgba(33, 40, 48, .01), 0px -1px 4px rgba(33, 40, 48, .01), 0 16px 16px rgba(33, 40, 48, .01);
        }
    }

    .offcanvas-footer {
        border-top: 1px solid rgb(204, 210, 218);
    }

    // OFF CANVAS FOOTER
    .outline-bttn-line {
        color: #1B00FF;
        border: 1px solid #1B00FF;
        background-color: transparent;
        border-radius: 25px;
        padding: 4px 14px;

        &:hover {
            color: #ffffff;
            background-color: #1B00FF;
        }
    }

    // INDICATOR
    .indicator {
        width: 18px;
        height: 18px;
        border: 1px solid;
        position: relative;
        background-color: transparent;

        &::after {
            content: "";
            width: 8px;
            height: 8px;
            top: 50%;
            left: 50%;
            position: absolute;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
        }

        &.veg {
            border-color: #12CF2A;

            &::after {
                background-color: #12CF2A;
            }
        }

        &.non-veg {
            border-color: #DC3545;

            &::after {
                background-color: #DC3545;
            }
        }

        &.eggiterian {
            border-color: #f9b80c;

            &::after {
                background-color: #f9b80c;
            }
        }

        &.not-applicable {
            border-color: #d2d2d2;

            &::after {
                background-color: #d2d2d2;
            }
        }

    }

    .error {
        color: #d5061b;
    }

    // QUANTITY
    .qty {
        .count {
            color: #000;
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
            font-weight: 600;
            line-height: 25px;
            padding: 0 2px;
            min-width: 35px;
            text-align: center;
            background-color: transparent;
        }

        .btn.plus,
        .btn.minus {
            width: 25px;
            height: 25px;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
            border-radius: 50%;
            text-align: center;
            padding: 0;

            i {
                font-size: 16px;
            }

            &:focus {
                color: #161616;
            }

            &:hover,
            &:active {
                color: #fff;
            }
        }

        input {
            border: 0;
            width: 2%;
        }

        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input:disabled {
            background-color: white;
        }

        &.item-qty {
            .count {
                line-height: 20px;
                font-size: 12px;
                max-width: 30px;
            }

            .btn.plus,
            .btn.minus {
                width: 20px;
                height: 20px;
                line-height: 20px;

                i {
                    font-size: 14px;
                }
            }
        }
    }

    // CUSTOM COMMON TABLE
    .custom-common-table {
        .icQpbX {
            padding: 10px 0;
        }

        .cviWVp {
            padding: 0.5rem;
        }

        .ewqyob {
            max-width: fit-content;
            min-width: auto;
        }

        .jJhCqB div:first-child {
            white-space: normal;
        }

        &::-webkit-scrollbar {
            height: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: #f0f0f0;
        }

    }

    // LEAVE STATUS BG
    .paid-leave-bg {
        background-color: #d7d162 !important;
    }

    .unpaid-leave-bg {
        color: #fff !important;
        background-color: #c56234 !important;
    }

    .present-bg {
        color: #fff !important;
        background-color: #338b45 !important;
    }

    .absent-bg {
        color: #fff !important;
        background-color: #cd4202 !important;
    }

    .halfDay-leave-bg {
        background-color: #f1f1f1 !important;
    }

    .work-off-bg {
        background-color: #c1c1c1 !important;
    }

    // VEG NONVEG STATUS
    .veg-nonveg-status {
        display: inline-block;
        width: 15px;
        height: 15px;
        position: relative;
        border: 1px solid transparent;

        &::after {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
        }

        &.veg {
            border-color: #00A428;

            &::after {
                background-color: #00A428;
            }
        }

        &.non-veg {
            border-color: #FF1D1D;

            &::after {
                background-color: #FF1D1D;
            }
        }

        &.not-applicable {
            border-color: #d2d2d2;

            &::after {
                background-color: #d2d2d2;
            }
        }
    }

    // DANGER RADIO STYLE
    .danger-radio-style {
        label {
            cursor: pointer;
        }

        .form-check-input {
            width: 1.2em;
            height: 1.2em;
            margin-right: 10px;

            &:checked {
                background-color: #dc3545;
                border-color: #dc3545;
            }
        }
    }

    // SCROLLBAR CONTAINER
    .scrollbar-container {
        .nav-pills {
            .nav-item {
                .nav-link {
                    color: #000000;
                    display: flex;
                    align-items: center;

                    i {
                        margin-right: 12px;
                        line-height: 1;
                        font-size: 20px;
                        width: 18px;
                        opacity: 0.85;
                    }

                    &.active {
                        background-color: #f3f3f3;
                    }
                }
            }
        }
    }

    // PERMISSION STATUS
    .permission-status {
        .react-checkbox-tree {
            ol {
                padding-left: 0;

                li {
                    ol {
                        padding-left: 0;

                        li {
                            padding-left: 30px;
                        }
                    }
                }
            }

            .rct-node-parent {
                margin-bottom: 20px;
                position: relative;
                padding: 10px 0;
                background-color: #fff;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

                li {
                    padding: 5px 30px;
                }
            }

            .rct-node {
                display: block;
            }

            .rct-text {
                .rct-collapse-btn {
                    position: absolute;
                    right: 10px;
                    top: 15px;
                    border: 0;
                    box-shadow: none;
                    line-height: normal;
                    background-color: transparent;

                    span {
                        font-size: 24px;
                    }
                }
            }
        }

        label {
            display: flex;
            padding: 5px 30px;
            font-size: 14px;
            cursor: pointer;
            align-items: center;

            input {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            &:first-child {
                padding-left: 10px;
            }
        }

        input {
            cursor: pointer;
        }

        .common-list-group {
            label {
                padding-left: 10px;
            }
        }
    }

    .jJhCqB div:first-child {
        white-space: normal
    }

    // FONT STYLE BTTNS
    .font-style-bttns {
        cursor: pointer;
        margin-right: 10px;

        input {
            display: none;

            &:checked {
                &+span {
                    color: #ffffff;
                    background-color: #3E3E3E;
                }
            }
        }

        span {
            width: 30px;
            height: 30px;
            border-radius: 5px;

            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ECECEC;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    // EDIT IMAGE
    .edit-image {
        font-size: 24px;
        position: absolute;
        z-index: 2;
        right: -10px;
        top: -10px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // CUSTOMER ADDRESS
    .custom-dropdown-wrapper {
        .custom-drop-down {
            position: relative;

            .dropdown-icon {
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                bottom: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #F5F5F5;
                border: 1px solid #cccccc;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                transition: 0.15s ease-in-out;
                -webkit-transition: 0.15s ease-in-out;

            }

            .react-select-class {
                padding-left: 35px;
            }

            .css-1hb7zxy-IndicatorsContainer {
                display: none;
            }

            .form-control {
                padding-left: 45px;
            }

            &:focus-within {
                .dropdown-icon {
                    border-color: #424242;
                }
            }
        }

        .options-container {
            // margin-top: 5px;
            // background-color: #fff;
            // border: 2px solid #e3e3e3;
            // border-radius: 4px;
            // display: flex;
            // flex-direction: column;
            // box-shadow: 0 3px 7px 0 #e3e3e3;
            position: absolute;
            overflow: auto;
            z-index: 9;
            top: 4.5rem;
            left: 0;
            width: 100%;
            max-height: 250px;
            padding: 8px;
            border-radius: 7px;
            background: #fff;
            border: 1px solid #e9e9e9;
            box-shadow: 0 1px 2px rgb(204, 204, 204);

            .option {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.625rem;

                &:hover {
                    background-color: #f3f6ff;
                }
            }
        }


    }

    .customer-address {
        align-items: center;
        border: 1px solid #ccc;
        margin: 0;
        border-radius: 3px;
        padding: 10px 0;

        .default-address {
            color: #7B7B7B;
            padding: 4px 10px;
            font-size: 12px;
            border-radius: 25px;
            background-color: #E8E8E8;
        }
    }

    // ADDRESS CUSTOM RADIO BUTTON
    .address-custom-radio-bttm {
        display: flex;
        align-items: center;

        .form-row {
            background: #ffffff;
            border-radius: 8px;
            height: 30px;
            overflow: hidden;
            position: relative;
            width: 90px;
            border: 1px solid #ccc;
            display: flex;
            align-items: center;

            .form-input {
                -webkit-appearance: none;
                appearance: none;

                &::before {
                    content: '';
                    cursor: pointer;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }

                &:checked::before {
                    border-color: #dc3545;
                }

                &::after {
                    content: '';
                    cursor: pointer;
                    height: 15px;
                    border-radius: 50px;
                    border: 5px solid #ccc;
                    left: 10%;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    -webkit-transform: translate(0, -50%);
                    width: 15px;
                }

                &:checked::after {
                    border-color: #dc3545;
                }

                &:checked~.form-label {
                    color: #dc3545;
                }
            }

            .form-label {
                z-index: 1;
                position: relative;
                width: 100%;
                text-align: center;
                padding-left: 15px;
                margin: 0;
                cursor: pointer;
            }
        }
    }

    // CUSTOME SCROLL BAR
    ::-webkit-scrollbar {
        height: 1px;
        width: 2px;
    }

    ::-webkit-scrollbar-track {
        background: #ddd;
    }

    ::-webkit-scrollbar-thumb {
        background: #808080;
    }

    // DELETED ADDONS
    .deleted-addons {
        color: #dc3545 !important;
        text-decoration: line-through;
    }

    // DELETED ITEMS
    .deleted-items {
        color: #dc3545 !important;
        background-color: #FFEAEA;
        border: solid 1px #dc3545 !important;
    }

    .flex-1 {
        flex: 1 0;
    }

    .login-msg {
        top: 50%;
        z-index: 2;
        padding: 20px 40px;
        position: absolute;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);

        h1 {
            font-size: 60px;
            line-height: 65px;
        }
    }

    // USER PROFILE
    .user-profile {
        color: #424242;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: #c3c3c3;
    }

    // UPLOAD INVOICE BILL
    .upload-invoice-bill {
        .invoice-bill-input {
            padding: 4px 10px;
            border-radius: 3px;
            background-color: #f4f4f4;
            border: 1px solid #cccccc;

            input[type=file] {
                padding: 4px;
                margin: -4px;
                position: relative;
                outline: none;
            }
        }

    }

    // COMING SOON PATCH
    .coming-soon-patch {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(0 0 0 / 90%);
    }

    .sentiment-analysis-empty-state {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }

    // TABLE SHIMMER EFFECT
    .table-shommer-effect {
        table {
            tr {
                td {
                    border: 0;

                    .table-shimmer {
                        background: #f6f7f8;
                        background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
                        background-repeat: no-repeat;
                        background-size: 1000px 100%;
                        display: inline-block;
                        position: relative;
                        overflow: hidden;
                        padding: 12px 0;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            background: inherit;
                            animation: tableShimmer 1.5s infinite linear;
                        }

                        @keyframes tableShimmer {
                            0% {
                                background-position: -1000px 0;
                            }

                            100% {
                                background-position: 1000px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .table-row-shimmer {
        display: table-row;
    }

    .table-cell-shimmer {
        display: table-cell;
        padding: 10px;
        height: 20px;
        border-bottom: 1px solid #ddd;
    }

    // CARD SHIMMER EFFECT
    .placeholder-glow {
        .placeholder {
            opacity: 0.1;
            animation: placeholder-glow 0.75s ease-in-out infinite;
        }
    }


    .customize-table-border {
        table {
            background-color: #f8f8f8;

            tr {
                td {
                    padding: 0.1rem 0.5rem;
                }
            }
        }
    }

    .branch-map-view>div {
        width: 100% !important;
    }

    .custom-form-search {
        border: 1px solid #ccc;
    }

    // COMMON SWITCH TOGGLE BUTTON CSS
    .common-switch-toggle {
        .form-switch {
            .form-check-input {
                vertical-align: middle;
                margin-right: 5px;
            }

            label {
                vertical-align: middle;
            }
        }
    }

    .item-manage-thumbnail {
        .thumbnail {
            width: 100px;
            height: 90px;
            margin: 0 auto;
            position: relative;
    
            .edit-thumbnail {
                right: -10px;
                top: -10px;
                width: 30px;
                height: 30px;
                z-index: 2;
                border-radius: 50%;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;
            }
    
            .img-fluid {
                border-radius: 8px;
            }
        }
    }
}


[data-skin=dark]:not([data-sidebar=prime]) {
    .manage-item-page {
        .custom-tab-body {
            .price-variant-bg {
                background-color: #141926;
            }
        }
    }
}

.ag-root-wrapper-body.ag-layout-normal {
    height: auto !important;
    min-height: auto !important;
}

// PAGE COMMON LOADER
.page-common-loader {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 50%);
}

// SYNC ANIMATION 
.btn-icon.outline-none.btn {

    &:focus,
    &:active {
        outline: none;
        border: 0;
    }
}

// SYNC ANIMATION
.sync-animation {
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes rotate {
        from {
            transform: rotate(-360deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}

// APPLICABLE VIEW
.applicabled-view {
    background-color: #eff3fb;
    padding: 10px;
}

// 992 VIEW PORT MEDIA QUERY
@media only screen and (max-width: 992px) {
    body {
        .item-details-tabs.card {
            .file-sidebar {
                position: static;
                border: 0;
                width: 100%;
                overflow-x: auto;
                border-bottom: 1px solid #d6d6d6;
            }

            .file-content {
                position: static;
                padding: 10px !important;
            }
        }

        .item-master-tabs.page-in-tabs {
            .nav-pills {
                display: block;
                overflow-x: auto;

                .nav-item {
                    .nav-link {
                        padding: 10px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

// 992 VIEW PORT MEDIA QUERY
@media only screen and (max-width: 1200px) {
    body {

        .w-90,
        .w-95 {
            width: 100% !important;
        }
    }
}

// 1024 VIEW PORT MEDIA QUERY
@media only screen and (max-width: 1024px) {
    body {
          // ITEM DETAILS SLIDER TAB
          .item-details-slider-tab {
            height: auto !important;

            .custom-file-sidebar {
                &.card-body {
                    overflow-y: inherit;
                }
            }

            .file-sidebar,
            .file-content {
                position: static;
                padding: 0;
                width: 100%;
            }

            .file-sidebar {
                overflow-x: auto;
                padding: 0 !important;
                .nav-pills {
                    display: flex;
                    overflow-x: auto;
                    .nav-item {
                        width: auto;
                        margin-bottom: 0;
                        white-space: nowrap;
                        .nav-link {
                        
                            span {
                                position: static !important;
                                margin-left: 3px;
                            }
                        }
                    }
                }
            }

            .file-content {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}

// TABLET VIEW MEDIA QUERY
@media only screen and (max-width: 820px) {
    body {
        .tab-text-left {
            text-align: left !important;
        }

        .offcanvas.offcanvas-end,
        .w-30.offcanvas,
        .w-35.offcanvas,
        .w-40.offcanvas,
        .w-45.offcanvas,
        .w-50.offcanvas,
        .w-55.offcanvas {
            width: 90% !important;
        }

        .w-60.offcanvas {
            width: 95% !important;
        }


        .offcanvas.offcanvas-end {
            &.off-canvas-custom-width {
                width: 80%;
                padding: 1rem;
            }
        }

        // ITEM DETAILS SLIDER TAB
        .item-details-slider-tab {
            height: auto !important;

            .custom-file-sidebar {
                &.card-body {
                    overflow-y: inherit;
                }
            }

            .file-sidebar,
            .file-content {
                position: static;
                padding: 0;
                width: 100%;
            }

            .file-sidebar {
                overflow-x: auto;
                padding: 0 !important;
                .nav-pills {
                    display: flex;
                    overflow-x: auto;
                    .nav-item {
                        margin-bottom: 0;
                      
                    }
                }
            }

            .file-content {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}

// IPAD MIN VIEW PORT
@media only screen and (max-width: 768px) {
    body {
        .sm-fs-12 {
            font-size: 12px !important;
        }
    }
}

// MOBILE VIEW MEDIA QUERY
@media only screen and (max-width: 480px) {
    body {
        .ms-3 {
            margin-left: 0.8rem !important;
        }

        .sidebar-logo {
            img {
                width: 150px !important;
            }
        }

        .w-80 {
            width: 95% !important;
        }

        .sm-d-block {
            display: block !important;
        }

        .offcanvas.offcanvas-end {
            .offcanvas-footer {
                padding: 0.5rem;
            }
        }

    }

    .sm-mt-2 {
        margin-top: 10px !important;
    }

    .sm-d-none {
        display: none !important;
    }

    .sm-d-block {
        display: block !important;
    }

    .sm-p-0 {
        padding: 0 !important;
    }

    .sm-pl-0 {
        padding-left: 0 !important;
    }

    .offcanvas.offcanvas-end {
        &.off-canvas-custom-width {
            width: 95%;
        }
    }

    // 
    .custom-file-sidebar {
        .file-sidebar {
            position: static;
            width: 100%;
        }

        .file-content {
            position: static;
        }
    }
}

@media screen {
    .bill-print-wrapper {
        visibility: hidden;
    }
}