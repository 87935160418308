.social-handle-row {
    &:last-child {
        margin-bottom: 0  !important;
    }

    .form-switch {
        .form-check-input {
            width: 3em;
            height: 1.5em;
            &:focus,
            &:active {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .disable-class {
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgb(255 255 255 / 68%);
        }
    }
}
