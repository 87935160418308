.all-remove-table {
    text-align: right;
}

.table-selected-badge {
    display: flex;
    flex-wrap: wrap;

    li {
        display: block;
        font-size: 14px;
        position: relative;
        height: 32px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.6);
        line-height: 32px;
        padding: 0 12px;
        border-radius: 16px;
        background-color: #e4e4e4;

        .btn {
            color: #6b6b6b;
            padding: 0;
            margin-left: 5px;
            border: 0;
            background-color: transparent;
            &:hover,
            &:focus,
            &:active {
                color: #000;
                background-color: transparent;
            }
        }
    }
}