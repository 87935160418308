.main-file-manager {
    .nav-sidebar {
        .nav-link {
            position: relative;
        }
    }
}

.manage-item-page {

    .item-manage-thumbnail {
        .thumbnail {
            width: 100px;
            height: 100px;
            margin: 0 auto;
            position: relative;
            .edit-thumbnail {
                right: -10px;
                top: -10px;
                width: 30px;
                height: 30px;
                z-index: 2;
                border-radius: 50%;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;
            }
            .img-fluid {
                border-radius: 8px;
            }
        }
    }
    
    .order-indicator {
        position: absolute;
        width: 8px;
        height: 8px;
        right: 0;
        border-radius: 50%;
        background-color: #12CF2A;
        top: 10px;
    }

    .custom-update-image {
        width: 100%;
        height: 70px;
        .custom-file-label {
            border-radius: 15px;
            border: 2px dashed #ccc;
            i {
                font-size: 24px;
            }
        }
    }

    .upload-image {
        width: 100%;
        height: 70px;
        position: relative;
        margin-bottom: 20px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 15px;
        }

        .remove-image {
            left: 50%;
            bottom: -10px;
            width: 25px;
            height: 25px;
            z-index: 2;
            border-radius: 50%;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            background-color: #FF0F0F;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
        }
    }

    .border-divider {
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #ccc;
        position: absolute;
    }

    .nav-tabs.sale-on-point-tabs {
        margin-bottom: 20px;
    }

    .custom-tab-body {
        .price-variant-bg {
            background-color: #F8F9FC;
            padding: 20px;
        }
    }
}
