.manage-item-page {
  

    .item-details-name {
        display: flex;
        flex-wrap: wrap;
        align-items: normal;
        height: 100%;
    }

    .order-indicator {
        position: absolute;
        width: 8px;
        height: 8px;
        right: 0;
        border-radius: 50%;
        background-color: #12CF2A;
        top: 10px;
    }

    .order-pending {
        position: absolute;
        width: 8px;
        height: 8px;
        right: 0;
        border-radius: 50%;
        background-color: #f63e3e;
        top: 10px;
    }

    .custom-update-image {
        width: 100%;
        height: 100%;
        position: relative;

        &.gallery {
            height: 70px;
            overflow: hidden;
            border-radius: 8px;
            border: 1px solid #ccc;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .custom-file-label {
            border-radius: 8px;
            border: 2px dashed #ccc;
        }

        .edit-image {
            font-size: 24px;
            position: absolute;
            z-index: 2;
            right: -10px;
            top: -10px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    .upload-image {
        width: 100%;
        height: 70px;
        position: relative;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 15px;
        }

        .remove-image {
            left: 50%;
            bottom: -10px;
            width: 25px;
            height: 25px;
            z-index: 2;
            border-radius: 50%;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            background-color: #FF0F0F;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
        }
    }

    .border-divider {
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #ccc;
        position: absolute;
    }

    .nav-tabs.sale-on-point-tabs {
        margin-bottom: 20px;
    }

    .custom-tab-body {
        .price-variant-bg {
            background-color: #F8F9FC;
            padding: 20px;

            &.add-variant-view {
                display: none;
            }
        }
    }

    .image-galler-view {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        .custom-update-image {
            width: 80px;
            height: 70px;
        }
    }
}

.magic-bttn.btn-white {
    color: #506fd9;
    padding: 4px 8px;
    border-color: #506fd9;

    &:hover {
        color: #ffffff;
        background-color: #506fd9;
    }
}

.card-one.item-details-tabs {
    // height: calc(100vh - 330px);

    .scrollbar-container {
        .nav-pills {
            .nav-item {
                .nav-link {
                    &.active {
                        background-color: #eff3fb;
                    }
                }
            }
        }
    }
}

// MOBILE VIEW MEDIA QUERY
@media only screen and (max-width: 480px) {
    .manage-item-page {
        .custom-update-image {
            &.gallery {
                width: 80px;
                height: 70px;
            }
        }
    }

    .item-master-tabs.page-in-tabs {
        .nav-pills {
            display: flex;
            overflow-x: auto;

            .nav-item {
                .nav-link {
                    padding: 10px 30px 10px 10px;
                    white-space: nowrap;
                }
            }
        }
    }
}