.custom-update-image {
    position: relative;
    width: 100px;
    height: 100px;

    .form-control {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
    }

    .custom-file-label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #ced4da;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
      }
}

.dragable-option {
    cursor: all-scroll;
}

.dragging {
    background-color: lightgray;
  }