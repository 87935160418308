.sub-category-tabs {
    position: relative;
    .sab-menu-tabs {
        overflow-y: auto;
        background: #ffffff;
        height: calc(100vh - 160px);
        box-shadow: 1px 0 1px rgba(33, 40, 48, 0.01),
                    4px 0 4px rgba(33, 40, 48, 0.01),
                    16px 0 16px rgba(33, 40, 48, 0.01);
    }

    .sub-menu-content {
        overflow-y: auto;
        background: #ffffff;
        height: calc(100vh - 160px);
    }
    .nav-pills {
        .nav-item {
            background-color: #fff;
            margin-bottom: 3px;

            .nav-link {
                color: #343434;
                display: block;
                align-items: center;
                justify-content: center;
                border: 0;
                padding: 15px 12px;
                border-radius: 0;
                border-left: 5px solid transparent;
                background-color: transparent;
                border-bottom: 1px solid #eeeeee;

                &.active {
                    color: #dc3545;
                    font-weight: 600;
                    border-left: 5px solid #dc3545;
                }
            }
        }
    }

}

.card-one {
    &.variant-card {
        height: 120px;
        border: 1px solid transparent;
        box-shadow: 0px 1px 5px 1px rgba(64, 60, 67, 0.16);
        &:hover {
            border-color: #dc3545;
        }
    
        .indicator-varient {
            // position: absolute;
            // left: 10px;
            // top: 10px;
            display: flex;
            align-items: center;
        }
        a {
        
            h3 {
                margin-top: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box !important;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        &.disabled-variant {
            background-color: #ffecec;
            box-shadow: none;
            border: 0;
            a {
                cursor: default !important;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .sub-category-tabs {
        .sab-menu-tabs,
        .sub-menu-content {
            height: auto
        }
    }
}
@media only screen and (max-width: 480px) {
    .sub-category-tabs {
        .sab-menu-tabs,
        .sub-menu-content {
            height: auto
        }
    }
}