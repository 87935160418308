.bill-print-wrapper {
    .bill-print-header {
        padding: 1rem 0;
        font-size: 22px;
    }

    .prints-addons-details {
        display: flex;
        .addons-details-left {
            flex: 10% 0;
        }

        .addons-details-right {
            flex: 90% 0;
        }
    }
}